const IconTwitter = ({ fill = '#ffffff', className, dataTestid = 'circle-twitter' }) => (
  <div>
    <svg
      className={className}
      data-testid={dataTestid}
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19.5" cy="19.5" r="19" stroke="white" />
      <path
        className={`${className}-path`}
        data-testid={`${dataTestid}-path`}
        d="M25.4586 16.0347C25.4647 16.1687 25.4647 16.2967 25.4647 16.4308C25.4708 20.4953 22.3757 25.1875 16.7157 25.1875C15.0463 25.1875 13.4074 24.7061 12 23.8042C12.2437 23.8347 12.4874 23.8469 12.7311 23.8469C14.1141 23.8469 15.4606 23.3837 16.5512 22.5245C15.2352 22.5001 14.0776 21.6409 13.6755 20.3856C14.1385 20.477 14.6137 20.4587 15.0646 20.3308C13.6328 20.0505 12.6032 18.789 12.5971 17.3204C12.5971 17.3083 12.5971 17.2961 12.5971 17.2839C13.0236 17.5215 13.5049 17.6556 13.9923 17.6678C12.6458 16.7659 12.2254 14.9682 13.0418 13.5606C14.6076 15.4862 16.9106 16.6501 19.3842 16.7781C19.1344 15.7117 19.4756 14.5904 20.2738 13.8348C21.5106 12.6709 23.4602 12.7318 24.63 13.9689C25.3184 13.8348 25.9825 13.5789 26.5857 13.2193C26.3542 13.9323 25.8729 14.5356 25.2331 14.9195C25.8424 14.8464 26.4395 14.6818 27 14.4381C26.5857 15.0597 26.0617 15.5959 25.4586 16.0347Z"
        fill={fill}
      />
    </svg>
  </div>
);

export default IconTwitter;
